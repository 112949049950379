<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the bond order of the system described by the molecular orbital electron
        configuration shown below:
      </p>

      <p class="mb-5 pl-8">
        <stemble-latex :content="moConfiguration" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        :prepend-text="'$\\text{Bond Order:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question49',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {input1: null},
    };
  },
  computed: {
    arrayVariable() {
      return this.taskState.getValueBySymbol('arrayVariable').content.value;
    },
    moConfiguration() {
      if (this.arrayVariable === 1) {
        return '$(\\sigma_{1s})^{2}(\\sigma_{1s}^{*})^{2}(\\sigma_{2s})^{2}$';
      } else if (this.arrayVariable === 2) {
        return '$(\\sigma_{1s})^{2}(\\sigma_{1s}^{*})^{2}(\\sigma_{2s})^{2}(\\pi_{2p})^1$';
      } else if (this.arrayVariable === 3) {
        return '$(\\sigma_{1s})^{2}(\\sigma_{1s}^{*})^{2}(\\sigma_{2s})^{2}(\\pi_{2p})^2$';
      } else if (this.arrayVariable === 4) {
        return '$(\\sigma_{1s})^{2}(\\sigma_{1s}^{*})^{2}(\\sigma_{2s})^{2}(\\pi_{2p})^3$';
      } else if (this.arrayVariable === 5) {
        return '$(\\sigma_{1s})^{2}(\\sigma_{1s}^{*})^{2}(\\sigma_{2s})^{2}(\\pi_{2p})^4$';
      } else if (this.arrayVariable === 6) {
        return '$(\\sigma_{1s})^{2}(\\sigma_{1s}^{*})^{2}(\\sigma_{2s})^{2}(\\pi_{2p})^4(\\pi_{2p}^*)^1$';
      } else if (this.arrayVariable === 7) {
        return '$(\\sigma_{1s})^{2}(\\sigma_{1s}^{*})^{2}(\\sigma_{2s})^{2}(\\pi_{2p})^4(\\pi_{2p}^*)^2$';
      } else if (this.arrayVariable === 8) {
        return '$(\\sigma_{1s})^{2}(\\sigma_{1s}^{*})^{2}(\\sigma_{2s})^{2}(\\pi_{2p})^4(\\pi_{2p}^*)^3$';
      }
    },
  },
};
</script>
